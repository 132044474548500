// see https://www.drupal.org/docs/7/api/javascript-api/managing-javascript-in-drupal-7
(function ($) {
  Drupal.behaviors.customRemedialClasses = {
    attach: function (context, settings) {
      if ($('#block-system-main article.node-tech > .embed-2').children('.view-display-id-block_archive').length > 0) {
        $('#block-system-main article.node-tech > .embed-2').addClass('custom-archive-class');
      }
    }
  };
  Drupal.behaviors.collapsemenu = {
    attach: function (context, settings) {
      $(window).bind('scroll', function () {
          if ($(window).scrollTop() > 50) {
              $('#header').addClass('fixed');
          } else {
              $('#header').removeClass('fixed');
          }
      });
    }
  };
  Drupal.behaviors.dropdownmenu = {
    attach: function (context, settings) {
      $('#block-menu-block-1 .menu').dropit();
      var submenu = $('#block-menu-block-1 .menu.dropit .dropit-submenu');
      if (!submenu.parent().hasClass('custom-menu-wrapper')) { // wrap only once
        submenu.wrap("<div class='custom-menu-wrapper'></div>");
      }
      /*$('#block-menu-block-1 .menu').dropit({
        action: 'click', // The open action for the trigger
        submenuEl: 'ul', // The submenu element
        triggerEl: 'a', // The trigger element
        triggerParentEl: 'li' // The trigger parent element
      });*/
      $('#block-menu-block-3 .menu').dropit(); // mobile only menu
    }
  };
  Drupal.behaviors.responsivemenublock = {
    attach: function (context, settings) {
      $('#responsive-toggler, #closebtn').click(function() {
        $('body').toggleClass('nav-responsive-on');
        /*
        // Deactivate the default behavior of going to the next page on click
        return false;
        */
      });
    }
  };

  // Toggle login form to login most users through shibboleth link
  Drupal.behaviors.toggform = {
    attach: function (context, settings) {
      $('#shib_login_url').prependTo($('#block-system-main'));
      $('#shib_login_url').css('font-size', '1.4em').css('margin-bottom', '3em');
      $('#shib_login_url a').css('text-decoration', 'underline').before('> ');
      $('form#user-login').css('display', 'none');
      // add link
      var toggleanchor = Drupal.t('Guest login');
      $('form#user-login').before('<a id="togg-link" style="font-size: .8em" href="#">' + toggleanchor + '</a>');
      /*$('form#user-login:not(.toggform-processed)', context).addClass('toggform-processed').each(function() {
        $(this).hide();
      });*/
    }
  };
  Drupal.behaviors.togglogin = {
    attach: function (context, settings) {
      $('a#togg-link:not(.togglogin-processed)', context).addClass('togglogin-processed').each(function() {
        $(this).click(function() {
          $('form#user-login').toggle(400);
          return false;
        });
      });
    }
  };

}(jQuery));
// second method - besides DrupalBehavior
(function ($) {
  $( window ).resize( function(){
    var div_height = $('.views_slideshow_cycle_slide').height();
    $('.views_slideshow_cycle_teaser_section').height(div_height);
  });
})(jQuery);

